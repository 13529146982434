<template>
    <div>
        <div class="mb-2 font-bold" v-if="standardAccount == 0">Ange standardkonto för leverantören</div>
        <VouchersTable
            :voucher-rows="voucherRowsLocalFiltered"
            :account-plan-items="accountPlanItems"
            :write-comments="false"
            :periodize="periodize"
            :financial-years="financialYears"
            :source-date="sourceDate"
            @updateValues="updateVoucherRow"
            @newItemCreation="createNewItemComponent"
            @removeVoucherRow="removeVoucherRow"
        />
    </div>
</template>
<script>
import { mapState } from "vuex";
import Accruals from "@/utility/accruals";
import Enum from "./documents.enum";

export default {
    props: ["voucherRows", "selectedCompanyType", "formData", "accountPlanItems", "financialYears", "sourceDate", "periodize"],
    data() {
        return {
            voucherRowsLocal: [
                { id: 0, account: Enum.accounts.SupplierDebt, debit: 0, credit: 0, text: "", readOnly: true, startDate: null, endDate: null, predefined: true },
                { id: 1, account: Enum.accounts.IncommingVat, debit: 0, credit: 0, text: "", readOnly: true, startDate: null, endDate: null, predefined: true },
                { id: 2, account: Enum.accounts.EuVatDebet, debit: 0, credit: 0, text: "", readOnly: true, startDate: null, endDate: null, predefined: true },
                { id: 3, account: Enum.accounts.EuVatCredit, debit: 0, credit: 0, text: "", readOnly: true, startDate: null, endDate: null, predefined: true },
                { id: 4, account: Enum.accounts.RoundingAccount, debit: 0, credit: 0, text: "", readOnly: true, startDate: null, endDate: null, predefined: true },
                { id: 5, account: Enum.accounts.ReminderAccount, debit: 0, credit: 0, text: "", readOnly: true, startDate: null, endDate: null, predefined: true },
            ],
            voucherRowsLocalFiltered: [],
            tempStandardAccount: null,
            standardAccount: undefined,
        };
    },
    components: {
        VouchersTable: () => import(/* webpackChunkName: "VouchersTable" */ "@/components/vouchersTable/v1/VouchersTable.vue"),
    },
    watch: {
        formData: {
            handler() {
                this.reassambleVoucherRows();
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        handlePension({ index, account, debit, comment, startDate, endDate }) {
            this.voucherRowsLocalFiltered[index].account = account;
            this.voucherRowsLocalFiltered[index].debit = Number(this.formData["grossAmount"]);
            this.voucherRowsLocalFiltered[index].credit = 0;
            this.voucherRowsLocalFiltered[index].text = comment;
            this.voucherRowsLocalFiltered[index].startDate = startDate;
            this.voucherRowsLocalFiltered[index].endDate = endDate;

            var pensionsAccountIndex = this.voucherRowsLocalFiltered.findIndex(row => row.account == 7533);
            var pensionBalanceAccountIndex = this.voucherRowsLocalFiltered.findIndex(row => row.account == 2514);

            if (pensionsAccountIndex == -1) {
                this.voucherRowsLocalFiltered.push({
                    id: this.voucherRowsLocalFiltered.length + this.voucherRowsLocal.length,
                    account: 7533,
                    debit: this.calculatePensionVatPercentage(debit),
                    credit: 0,
                    text: comment,
                    readOnly: false,
                    startDate: startDate,
                    endDate: endDate,
                    predefined: false,
                });
            } else {
                this.voucherRowsLocalFiltered[pensionsAccountIndex].debit = this.calculatePensionVatPercentage(debit);
            }

            if (pensionBalanceAccountIndex == -1) {
                this.voucherRowsLocalFiltered.push({
                    id: this.voucherRowsLocalFiltered.length + this.voucherRowsLocal.length,
                    account: 2514,
                    debit: 0,
                    credit: this.calculatePensionVatPercentage(debit),
                    text: comment,
                    readOnly: false,
                    startDate: startDate,
                    endDate: endDate,
                    predefined: false,
                });
            } else {
                this.voucherRowsLocalFiltered[pensionBalanceAccountIndex].credit = this.calculatePensionVatPercentage(debit);
            }

            this.$emit("rowsUpdated", this.voucherRowsLocalFiltered);
            this.reassambleVoucherRows();
        },

        calculatePensionVatPercentage(amount) {
            return (amount * 24.26) / 100;
        },

        updateVoucherRow({ index, account, debit, credit, comment, startDate, endDate }) {
            if (Object.values(this.formData["supplierSearchOption"]).length > 0) {
                const accountLocal =
                    this.selectedCompanyType == Enum.AccountCompanyType.Aktiebolag
                        ? Number(this.formData["supplierSearchOption"].limitedDefaultAccount)
                        : Number(this.formData["supplierSearchOption"].individualDefaultAccount);

                if (accountLocal == this.voucherRowsLocalFiltered[index].account) this.tempStandardAccount = account;
            }
            if (account == 7410) {
                this.handlePension({ index, account, debit, comment, startDate, endDate });
            } else {
                const oldStartDate = this.voucherRowsLocalFiltered[index].startDate;
                const oldEndDate = this.voucherRowsLocalFiltered[index].endDate;

                this.voucherRowsLocalFiltered[index].account = account;
                this.voucherRowsLocalFiltered[index].debit = debit;
                this.voucherRowsLocalFiltered[index].credit = credit;
                this.voucherRowsLocalFiltered[index].text = comment;
                this.voucherRowsLocalFiltered[index].startDate = startDate;
                this.voucherRowsLocalFiltered[index].endDate = endDate;
                this.$emit("rowsUpdated", this.voucherRowsLocalFiltered);

                if ((startDate && endDate && (oldStartDate != startDate || oldEndDate != endDate)) || (oldStartDate && oldEndDate)) {
                    this.reassambleVoucherRows();
                }
            }
        },
        createNewItemComponent() {
            this.voucherRowsLocalFiltered.push({
                id: this.voucherRowsLocalFiltered.length + this.voucherRowsLocal.length,
                account: null,
                debit: 0,
                credit: 0,
                text: "",
                readOnly: false,
                startDate: null,
                endDate: null,
                predefined: false,
            });
            this.$emit("rowsUpdated", this.voucherRowsLocalFiltered);
        },
        removeVoucherRow(index) {
            let newItems = this.voucherRowsLocalFiltered;
            const lastRow = index === newItems.length - 1;
            newItems.splice(index, 1);
            this.voucherRowsLocalFiltered = newItems;

            if (this.voucherRowsLocalFiltered.filter(item => !item.readOnly).length === 0 || lastRow) {
                this.voucherRowsLocalFiltered.push({
                    id: this.voucherRowsLocalFiltered.length + this.voucherRowsLocal.length,
                    account: null,
                    debit: 0,
                    credit: 0,
                    text: "",
                    readOnly: false,
                    startDate: null,
                    endDate: null,
                });
            }
            this.$emit("rowsUpdated", this.voucherRowsLocalFiltered);
            this.reassambleVoucherRows();
        },
        reassambleVoucherRows() {
            var userRows = this.voucherRowsLocalFiltered.filter(row => !row.readOnly && !row.predefined && (row.credit > 0 || row.debit > 0));
            this.voucherRowsLocalFiltered = [];

            this.voucherRowsLocal.forEach(row => {
                row.credit = 0;
                row.debit = 0;
            });

            if (Number(this.formData["grossAmount"]) > 0) this.voucherRowsLocal[0].credit = Number(this.formData["grossAmount"]);
            else this.voucherRowsLocal[0].debit = -Number(this.formData["grossAmount"]);

            if (Number(this.formData["roundingAmount"]) > 0) this.voucherRowsLocal[4].debit = Number(this.formData["roundingAmount"]);
            else this.voucherRowsLocal[4].credit = -Number(this.formData["roundingAmount"]);

            this.voucherRowsLocal[5].debit = Number(this.formData["reminderFee"]);

            if (this.formData["supplierCountry"] == Enum.supplierCountry.Sweden) {
                if (this.formData["reverseConstruction"] == Enum.reverseConstruction.No) {
                    if (Number(this.formData["vatAmount"]) > 0) this.voucherRowsLocal[1].debit = Number(this.formData["vatAmount"]);
                    else this.voucherRowsLocal[1].credit = -Number(this.formData["vatAmount"]);
                } else {
                    this.voucherRowsLocal[2].debit = Number(this.formData["vatAmount"]);
                    this.voucherRowsLocal[3].credit = Number(this.formData["vatAmount"]);
                }
            } else if (this.formData["momsNumber"] == Enum.momsNumber.Reported || this.formData["reverseConstruction"] == Enum.reverseConstruction.Yes) {
                this.voucherRowsLocal[2].debit = Number(this.formData["vatAmount"]);
                this.voucherRowsLocal[3].credit = Number(this.formData["vatAmount"]);
            }

            this.voucherRowsLocalFiltered = this.voucherRowsLocal.filter(row => row.debit != 0 || row.credit != 0);
            if (Object.values(this.formData["supplierSearchOption"]).length > 0) {
                var account =
                    this.selectedCompanyType == Enum.AccountCompanyType.Aktiebolag
                        ? Number(this.formData["supplierSearchOption"].limitedDefaultAccount)
                        : Number(this.formData["supplierSearchOption"].individualDefaultAccount);

                if (this.tempStandardAccount) account = this.tempStandardAccount;
                this.standardAccount = account;

                if (!userRows.some(r => r.account == account)) {
                    if (account != 0) {
                        this.voucherRowsLocalFiltered.push({
                            id: this.voucherRowsLocalFiltered.length,
                            account: account,
                            debit: Number(this.formData["netAmount"]) > 0 ? Number(this.formData["netAmount"]) : 0,
                            credit: Number(this.formData["netAmount"]) < 0 ? -Number(this.formData["netAmount"]) : 0,
                            text: "",
                            readOnly: false,
                            startDate: null,
                            endDate: null,
                            predefined: false,
                        });
                    }
                } else {
                    var defaultRow = userRows.find(r => r.account == account);
                    defaultRow.debit = Number(this.formData["netAmount"]) > 0 ? Number(this.formData["netAmount"]) : 0;
                    defaultRow.credit = Number(this.formData["netAmount"]) < 0 ? -Number(this.formData["netAmount"]) : 0;
                }
            }

            userRows = Accruals.getAccruals(userRows, this.voucherRowsLocalFiltered.length + this.voucherRowsLocal.length, this.accountPlanItems, this.sourceDate);

            this.voucherRowsLocalFiltered = [...this.voucherRowsLocalFiltered, ...userRows];
            this.createNewItemComponent();
            this.$emit("rowsUpdated", this.voucherRowsLocalFiltered);
        },
        GetVoucherRowsFiltered() {
            return this.voucherRowsLocalFiltered;
        },
    },
    computed: {
        ...mapState({
            accountsInfo: state => state.accountPlans,
        }),
    },
};
</script>

<style lang="postcss"></style>
